<template>
  <Dialog :dialog.sync="deleteDialog" :dialogWidth="750">
    <template v-slot:title> Delete {{ type }}</template>
    <template v-slot:body>
      <v-row class="delete-dialog">
        <v-col md="2" class="py-0 text-right my-auto">
          <span class="svg-icon svg-icon-lg delete-confirm-icon">
            <!--begin::Svg Icon-->
            <!-- <inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" /> -->
            <img :src="$assetURL('media/custom-svg/attention-circle.svg')" />
            <!--end::Svg Icon-->
          </span>
        </v-col>
        <v-col md="10" class="py-0 my-auto">
          <p class="btx-p m-0">
            <span class="text-capitalize">{{ type }} </span>
            <b class="red--text text--lighten-1">{{ deleteText }}</b> will be
            deleted forever and cannot be retrieved later.<br />Are you sure
            about deleting it?
          </p>
        </v-col>
        <v-col v-if="deleteNote" md="12" class="pb-0 my-auto">
          <em>Note: {{ deleteNote }}</em>
        </v-col>
      </v-row>
    </template>
    <template v-slot:action>
      <v-btn
        class="white--text"
        :loading="deleteButton"
        :disabled="deleteButton"
        depressed
        color="red lighten-1"
        tile
        v-on:click="deleteRow()"
      >
        Yes! Delete
      </v-btn>
      <v-btn
        depressed
        tile
        :disabled="deleteButton"
        v-on:click="$emit('close', true)"
      >
        No, Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Dialog from "@/view/components/Dialog";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
export default {
  name: "delete-template",
  title: "Delete Template",
  props: {
    type: {
      type: String,
      default: null,
    },
    deleteText: {
      type: String,
      default: null,
    },
    deleteEndpoint: {
      type: String,
      default: null,
    },
    deleteNote: {
      type: String,
      default: null,
    },
    deleteDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteButton: false,
    };
  },
  methods: {
    deleteRow() {
      ApiService.setHeader();
      this.deleteButton = true;
      ApiService.delete(this.deleteEndpoint)
        .then(() => {
          this.$emit("close", true);
          this.$emit("delete:success", true);
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: this.type + " is Deleted Successfully" },
          ]);
        })
        .catch(({ response }) => {
          this.$store.commit(SET_ERROR, [{ model: true, message: response }]);
        })
        .finally(() => {
          this.deleteButton = false;
        });
    },
  },
  components: {
    Dialog,
  },
};
</script>
